<template>
    <v-container>
        <v-row>
            <v-col cols=7><h2 class="primary--text mb-2" style="font-weight:400;">Incident Report</h2> </v-col>
            <v-col cols=5 class="text-right"><p class="primary--text">{{ now_date }}</p></v-col>
        </v-row>
        
        <div v-if="projects.length > 0">
            <v-card
            elevation="2"
            outlined
            class="mb-3"
            v-for="project in projects" v-bind:key="project.id"
            >
            <v-card-title style="padding-bottom:0px">
                <v-row>
                    <v-col cols=12><h4 class="primary--text">{{ project.name }}</h4></v-col>
                </v-row>
            </v-card-title>
            <v-card-text style="padding-bottom:0px;">
                <h4 class="primary--text">{{ project.project_code }}</h4>
            </v-card-text>
            <v-card-actions>
                <v-row>
                <v-col cols=6><v-btn style="text-transform: capitalize;" class="primary" @click="viewIncident(project)">View Incident</v-btn></v-col>
                <v-col cols=6><v-btn style="text-transform: capitalize;" @click="addIncident(project)" class="red white--text float-right">Add Incident</v-btn></v-col>
                </v-row>
            </v-card-actions>
            </v-card>
        </div>
        <div v-else>
            <v-alert type="error">No projects found</v-alert>
            <v-btn small style="text-transform: capitalize;" class="primary" @click="refreshProjects">Refresh</v-btn>
        </div>

    </v-container>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import date from 'date-and-time'
export default {
    name:'Project',
    data: () => ({
        now_date : ""
    }),
    methods:{
        ... mapActions (['fetchAllProjects']),
        updateProjectStatus (value){
            if(value == "approved"){
                return "success"
            }else if(value == "reject"){
                return "danger"
            }else{
                return "warning"
            }
        },
        addIncident (value) {
            this.$store.commit('setCurrentProject',value)
            this.$router.push('/incident/add')
        },
        viewIncident (value) {
            this.$store.commit('setCurrentProject',value)
            this.$router.push('/incident/view')
        },
        refreshProjects () {
            let user_p = JSON.parse(localStorage.getItem('user'))
            this.fetchAllProjects(user_p.id)
            location.reload()
        }
    },
    computed: {
        ... mapGetters ( {projects : "getAllProjects"})
    },
    mounted(){
        let now  = new Date();
        let now_dates = date.format(now, 'ddd, MMM DD YYYY');
        this.now_date = now_dates 
        let user = JSON.parse(localStorage.getItem('user'))
        
        if(user == null){
            this.$router.push('/')
        }else{
            if(user.incident != 1){
                this.$router.push('/home')
            }else{
                this.fetchAllProjects(user.id)
            }
        }
    }
}
</script>